import request from '@/utils/request';

//列表
export function fetchList(data) {
  return request({
    url : '/permission/list',
    method : 'post',
    data : data
  })
}

//删除
export function deletePermission(data) {
  return request({
    url : '/permission/deletePermission',
    method : 'post',
    data : data
  })
}
//批量删除
export function batchDeletePermission(data) {
  return request({
    url : '/permission/batchDeletePermission',
    method : 'post',
    data : data
  })
}

//新增
export function createPermission(data) {
  return request({
    url : '/permission/createPermission',
    method : 'post',
    data : data
  })
}
//修改
export function updatePermission(data) {
  return request({
    url :'/permission/updatePermission',
    method : 'post',
    data : data
  })
}

//全部
export function fetchAllPermission() {
  return request({
    url : '/permission/allPermission',
    method : 'get',
  })
}
